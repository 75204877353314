import { useContext, useEffect, useRef, useState } from "react";
import Price from "./Price/Price";
import { HomeContext } from "../../Context/HomeContext";
import DesignRose from "./DesignRose/DesignRose";
import Packaging from "./Packaging/Packaging";
import dataClient from "../../../../assets/Json/dataClient.json";
import DesignPanelToggler from "../DesignPanelToggler/DesignPanelToggler";

export default function DesignPanel() {
  const {
    rose,
    setRose,
    setActiveSection,
    activeSection,
    sectionRefs,
    setPrevActiveSection,
    loadingMain,
    flagScroll,
    flagScrollImage,
  } = useContext(HomeContext);

  const [data, setData] = useState({});

  useEffect(() => {
    setData(dataClient);
  }, []);

  function RoseColor({ color, isActive, onClick }) {
    return (
      <div
        style={{
          border: `${isActive ? "2px" : "1px"} solid ${
            isActive ? "#000000" : color.hex
          }`,
        }}
        onClick={onClick}
      >
        <div
          className="Rose-color-item"
          style={{
            backgroundColor: color.hex,
          }}
        />
        <i
          className="bi bi-check-lg Rose-color-check"
          style={{
            color: color.check,
            display: isActive ? "block" : "none",
          }}
        ></i>
      </div>
    );
  }

  const handleRoseColorChange = (color) => {
    setRose((prevState) => ({
      ...prevState,
      roseColor: color,
    }));
  };

  useEffect(() => {
    let observer;
    if (flagScroll && !flagScrollImage) {
      if (window.innerWidth >= 993) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const index = sectionRefs.findIndex(
                  (ref) => ref.current === entry.target
                );
                if (index !== -1) {
                  setPrevActiveSection(activeSection);
                  setActiveSection(index + 1);
                }
              }
            });
          },
          { root: null, threshold: 0.9 } // 40% از بخش دیده شود
        );

        sectionRefs.forEach(
          (ref) => ref.current && observer.observe(ref.current)
        );
      } else {
        console.log("ok");
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const index = sectionRefs.findIndex(
                  (ref) => ref.current === entry.target
                );
                if (index !== -1) {
                  setActiveSection(index + 1);
                }
              }
            });
          },
          { root: null, threshold: 0.4 } // 40% از بخش دیده شود
        );

        sectionRefs.forEach(
          (ref) => ref.current && observer.observe(ref.current)
        );
      }
    }

    return () => {
      if (flagScroll) {
        sectionRefs.forEach(
          (ref) => ref.current && observer.unobserve(ref.current)
        );
      }
    };
  }, [sectionRefs]);
  //   console.log(imageUploadRefMob);

  //   let observer1;
  //   if (window.innerWidth <= 992 ) {
  //     console.log("observer");
  //     observer1 = new IntersectionObserver(
  //       (entries) => {
  //         entries.forEach((entry) => {
  //           // اگر بخش ImageUpload وارد viewport شد
  //           if (entry.isIntersecting) {
  //             // اسکرول به وسط صفحه
  //             console.log("yes");
              
  //           } else {
  //             entry.target.scrollIntoView({
  //               behavior: "smooth",
  //               block: "center",
  //             });
  //           }
  //         });
  //       },
  //       { root: document.querySelector("#test1"), threshold: 0.2 }
  //     );

  //     if (imageUploadRefMob.current) {
  //       observer1.observe(imageUploadRefMob.current);
  //     }
  //   }

  //   return () => {
  //     if (window.innerWidth <= 992) {
  //       if (imageUploadRefMob.current) {
  //         observer1.unobserve(imageUploadRefMob.current);
  //       }
  //     }
  //   };
  // }, [imageUploadRefMob , flagScrollImage]);

  return (
    <>
      <div className="DesignPanel-container col-lg-12" id="test1">
        <section
          className="DesignPanel-tools"
          style={{
            overflowY: loadingMain ? "hidden" : "auto",
          }}
        >
          <div className="DesignPanel-tools-title-1">
            <div className="DesignPanel-tools-title-1-line"></div>
            <h3 className="">Your Rose</h3>
            <div className="DesignPanel-tools-title-1-line"></div>
          </div>

          <div
            className="DesignPanel-tools-typeRose"
            ref={(el) => (sectionRefs[0].current = el)}
          >
            <div className="DesignPanel-tools-typeRose-container">
              <div className="DesignPanel-tools-typeRose-title">
                <span>Choose Type</span>
              </div>

              <div className="DesignPanel-tools-typeRose-content">
                <section className="Rose-type ">
                  {data.design?.typeRose.map((type) => {
                    return (
                      <div
                        className="Rose-type-item"
                        key={type.id}
                        onClick={() => {
                          setRose((prevState) => ({
                            ...prevState,
                            roseType: type?.item,
                          }));
                        }}
                        style={{
                          border: `1px solid ${
                            rose?.roseType === type?.item
                              ? "#5C5E62"
                              : "#BFBFBF"
                          }`,
                          backgroundColor:
                            rose?.roseType === type.item
                              ? "#F8E8E9"
                              : "#ffffff",
                        }}
                      >
                        <div
                          className={`Rose-type-item-image ${
                            rose?.roseType == type.item ? "ActiveRose" : ""
                          }`}
                        >
                          <img src={type.image} alt="rose3" />
                        </div>
                        <span
                          className="Rose-type-item-text"
                          style={{
                            fontWeight:
                              rose?.roseType == type.item ? "700" : "400",
                            color:
                              rose?.roseType == type.item
                                ? "#000000"
                                : "#5C5E62",
                          }}
                        >
                          {type.title}
                        </span>
                      </div>
                    );
                  })}
                </section>
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-colorRose">
            <div className="DesignPanel-tools-colorRose-container">
              <div className="DesignPanel-tools-colorRose-title">
                <span>Choose Color</span>
              </div>

              <div className="DesignPanel-tools-colorRose-content">
                <section className="Rose-color">
                  {data.design?.colorRose.map((color) => (
                    <RoseColor
                      key={color?.code}
                      color={color}
                      isActive={rose?.roseColor?.code === color.code}
                      onClick={() => handleRoseColorChange(color)}
                    />
                  ))}
                </section>
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-title-2" >
            <div className="DesignPanel-tools-title-2-line"></div>
            <h3 className="">Design Your Rose</h3>
            <div className="DesignPanel-tools-title-2-line"></div>
          </div>

          <div ref={(el) => (sectionRefs[1].current = el)}>
            <DesignRose />
          </div>

          <div className="DesignPanel-tools-title-3">
            <div className="DesignPanel-tools-title-3-line"></div>
            <h3>Packaging</h3>
            <div className="DesignPanel-tools-title-3-line"></div>
          </div>

          <div ref={(el) => (sectionRefs[2].current = el)}>
            <Packaging />
          </div>

          <section className="DesignPanel-price">
            <Price />
          </section>
        </section>

        <DesignPanelToggler />
      </div>
    </>
  );
}
