import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import clientData from "../../../../../../../assets/Json/dataClient.json";
import Loading from "../../../../Loading/Loading";

export default function AddClipart() {
  const { cliPart, setCliPart, loading, setLoading } = useContext(HomeContext);

  const [listItem, setListItem] = useState([]);
  const [category, setCategory] = useState("All");
  // آرایه‌ای برای ذخیره وضعیت دیده شدن هر آیتم
  const [visibleItems, setVisibleItems] = useState([]);

  // یک آرایه ref برای هر آیتم
  const itemRefs = useRef([]);

  useEffect(() => {
    let list;
    if (category === "All") {
      list = clientData?.design?.clipart?.flatMap((item) => item.fileUrl);
      if (
        clientData?.design?.clipart &&
        clientData.design.clipart.length > 0 &&
        clientData.design.clipart[0].fileUrl &&
        clientData.design.clipart[0].fileUrl.length > 0 &&
        cliPart === null
      ) {
        setCliPart({
          name: clientData.design.clipart[0].fileUrl[0].name,
          file: clientData.design.clipart[0].fileUrl[0].file,
        });
      }
    } else {
      list =
        clientData?.design?.clipart?.find((item) => item.category === category)
          ?.fileUrl || [];
      if (list && list.length > 0 && cliPart === null) {
        setCliPart({
          name: list[0].name,
          file: list[0].file,
        });
      }
    }
    setListItem(list);
    // ریست کردن visibleItems برای لیست جدید (همه آیتم‌ها ابتدا نامرئی)
    setVisibleItems(Array(list.length).fill(false));
    // اطمینان از بروز شدن itemRefs
    itemRefs.current = Array(list.length)
      .fill()
      .map((_, i) => itemRefs.current[i] || null);
  }, [category]);

  // استفاده از IntersectionObserver برای lazy loading
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.dataset.index);
          if (entry.isIntersecting) {
            setVisibleItems((prev) => {
              const newVisible = [...prev];
              newVisible[index] = true;
              return newVisible;
            });
            // می‌توانید اگر نیازی به مشاهده بیشتر نباشد، unobserve کنید
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.9 }
    );

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    window.onerror = function(message, source, lineno, colno, error) {
      alert(`Error: ${message} at ${source}:${lineno}:${colno}`);
  };

    return () => {
      observer.disconnect();
    };
  }, [listItem]);

  const handleImageLoad = (index) => {
    setLoading((prev) => ({ ...prev, [index]: false }));
  };

  const handleImageError = (index) => {
    setLoading((prev) => ({ ...prev, [index]: false }));
  };

  const handleClipart = (name, file) => {
    setCliPart({ name, file });
  };

  return (
    <div className="AddClipart">
      <div className="AddClipart-container">
        <div className="AddClipart-title">
          <span>Add Clipart</span>
        </div>

        <div className="AddClipart-content">
          <div className="New-Clipart">
            <div className="New-Clipart-category">
              <label className="New-Clipart-category-title">
                Choose Category
              </label>
              <select
                className="New-Clipart-category-list"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="All">All</option>
                {clientData?.design?.clipart?.map((c, index) => (
                  <option key={index} value={c.category}>
                    {c.category}
                  </option>
                ))}
              </select>
            </div>

            <div className="New-Clipart-list row">
              {listItem?.map((i, index) => (
                <section
                  className="New-Clipart-list-item col-3"
                  key={i.id}
                  data-index={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                  style={{
                    border:
                      cliPart?.name === i.name ? "2px solid #B04953" : null,
                    backgroundColor:
                      i.name === cliPart?.name ? "#B0495340" : null,
                  }}
                >
                  {visibleItems[index] ? (
                    <div
                      className="New-Clipart-list-item-image"
                      onClick={() => handleClipart(i.name, i.file)}
                    >
                      {loading[index] !== false && <Loading />}
                      <img
                        src={i.file}
                        alt={i.name}
                        loading="lazy"
                        onLoad={() => handleImageLoad(index)}
                        onError={() => handleImageError(index)}
                      />
                    </div>
                  ) : (
                    // placeholder (مثلاً یک div خالی یا یک لودینگ کوچک)
                    <div style={{ height: "100px" }} />
                  )}
                  <div
                    className="New-Clipart-list-item-close"
                    style={{
                      display: cliPart?.name === i.name ? "inline" : "none",
                    }}
                    onClick={() => setCliPart(null)}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// import { useContext, useEffect, useState } from "react";
// import { HomeContext } from "../../../../../Context/HomeContext";
// import clientData from "../../../../../../../assets/Json/dataClient.json";
// import Loading from "../../../../Loading/Loading";
// // import { FixedSizeGrid as Grid } from "react-window";

// export default function AddClipart() {
//   const { cliPart, setCliPart, loading, setLoading } = useContext(HomeContext);

//   const [listItem, setListItem] = useState([]);
//   const [category, setCategory] = useState("All");
//   const [maxIndexToRender, setMaxIndexToRender] = useState(1);

//   useEffect(() => {
//     let list;
//     if (category === "All") {
//       // ترکیب تمام دسته‌بندی‌ها در یک آرایه‌ی مسطح
//       list = clientData?.design?.clipart?.flatMap((item) => item.fileUrl);
//       if (
//         clientData?.design?.clipart &&
//         clientData.design.clipart.length > 0 &&
//         clientData.design.clipart[0].fileUrl &&
//         clientData.design.clipart[0].fileUrl.length > 0 &&
//       cliPart === null
//       ) {
//         setCliPart({
//           name: clientData.design.clipart[0].fileUrl[0].name,
//           file: clientData.design.clipart[0].fileUrl[0].file,
//         });
//       }
//     } else {
//       list =
//         clientData?.design?.clipart?.find((item) => item.category === category)
//           ?.fileUrl || [];

//       if (list && list.length > 0 && cliPart === null) {
//         setCliPart({
//           name: list[0].name,
//           file: list[0].file,
//         });
//       }
//     }

//     setListItem(list);
//     setMaxIndexToRender(1); // ریست کردن تعداد رندر شده هنگام تغییر دسته
//   }, [category]);

//   const handleImageLoad = (index) => {
//     setLoading((prev) => ({ ...prev, [index]: false }));
//     // اگر این تصویر آخرین تصویر رندر شده بود، در صورت وجود آیتم بعدی، مقدار maxIndexToRender را افزایش می‌دهیم
//     if (index === maxIndexToRender - 1 && maxIndexToRender < listItem.length) {
//       setTimeout(() => {
//         setMaxIndexToRender((prev) => prev + 1);
//       }, 100);
//     }
//   };

//   const handleImageError = (index) => {
//     setLoading((prev) => ({ ...prev, [index]: false }));
//   };

//   const handleClipart = (name, file) => {
//     setCliPart({
//       name,
//       file,
//     });
//   };

//   return (
//     <>
//       <div className="AddClipart">
//         <div className="AddClipart-container">
//           <div className="AddClipart-title">
//             <span>Add Clipart</span>
//           </div>

//           <div className="AddClipart-content">
//             <div className="New-Clipart">
//               <div className="New-Clipart-category">
//                 <label className="New-Clipart-category-title">
//                   Choose Category
//                 </label>
//                 <select
//                   className="New-Clipart-category-list"
//                   onChange={(e) => {
//                     setCategory(e.target.value);
//                   }}
//                 >
//                   <option value="All">All</option>
//                   {clientData?.design?.clipart?.map((c, index) => {
//                     return (
//                       <option key={index} value={c.category}>
//                         {c.category}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>

//               <div className="New-Clipart-list row">
//                 {listItem?.slice(0, maxIndexToRender).map((i, index) => {
//                   if (index < maxIndexToRender) {
//                     return (
//                       <section
//                         className="New-Clipart-list-item col-3"
//                         key={i.id}
//                         style={{
//                           border:
//                             cliPart?.name === i.name
//                               ? "2px solid #B04953"
//                               : null,
//                           backgroundColor:
//                             i.name === cliPart?.name ? "#B0495340" : null,
//                         }}
//                       >
//                         <div
//                           className="New-Clipart-list-item-image"
//                           onClick={() => {
//                             handleClipart(i.name, i.file);
//                           }}
//                         >
//                           {loading[index] !== false && <Loading />}
//                           <img
//                             src={i.file}
//                             alt={i.name}
//                             loading="lazy"
//                             onLoad={() => handleImageLoad(index)}
//                             onError={() => handleImageError(index)}
//                           />
//                         </div>
//                         <div
//                           className="New-Clipart-list-item-close"
//                           style={{
//                             display:
//                               cliPart?.name === i.name ? "inline" : "none",
//                           }}
//                           onClick={() => {
//                             setCliPart(null);
//                           }}
//                         >
//                           <i className="bi bi-x-lg"></i>
//                         </div>
//                       </section>
//                     );
//                   } else {
//                     // آیتم‌هایی که هنوز قرار نیست رندر شوند (یا می‌توانند به صورت placeholder نمایش داده شوند)
//                     return null;
//                   }
//                 })}

//                 {/* {listItem?.map((i , index) => {
//                   return (
//                     <section
//                       className="New-Clipart-list-item col-3"
//                       key={i.id}
//                       style={{
//                         border:
//                           cliPart?.name === i.name ? "2px solid #B04953" : null,
//                         backgroundColor:
//                           i.name === cliPart?.name ? "#B0495340" : null,
//                       }}
//                     >
//                       <div
//                         className="New-Clipart-list-item-image"
//                         onClick={() => {
//                           handleClipart(i.name, i.file);
//                         }}
//                       >
//                         {loading[i.id - 1] !== false && <Loading />}
//                         <img
//                           src={i.file}
//                           alt={i.name}
//                           loading="lazy"
//                           onLoad={() => handleImageLoad(i.id - 1)}
//                           onError={() => handleImageError(i.id)}
//                         />
//                       </div>
//                       <div
//                         className="New-Clipart-list-item-close"
//                         style={{
//                           display: cliPart?.name === i.name ? "inline" : "none",
//                         }}
//                         onClick={() => {
//                           setCliPart(null);
//                         }}
//                       >
//                         <i className="bi bi-x-lg"></i>
//                       </div>
//                     </section>
//                   );
//                 })} */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
