// cropImage.js
export default function getCroppedImg(imageSrc, croppedAreaPixels) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext("2d");

      ctx.save();
      // تنظیم clipping به صورت دایره‌ای: شعاع برابر با نصف عرض (یا ارتفاع چون نسبت 1 است)
      ctx.beginPath();
      ctx.arc(croppedAreaPixels.width / 2, croppedAreaPixels.height / 2, croppedAreaPixels.width / 2, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.clip();

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      ctx.restore();

      resolve(canvas.toDataURL("image/png"));
    };
    image.onerror = (error) => {
      reject(error);
    };
  });
}
