import { useContext, useEffect } from "react";
import { HomeContext } from "../../../Context/HomeContext";
import textAdd from "../../../../../assets/textAdd.png";
import imageAdd from "../../../../../assets/imageAdd.png";
import clipartAdd from "../../../../../assets/clipartAdd.png";
import AddText from "./Components/AddText/AddText";
import AddImage from "./Components/AddImage/AddImage";
import ImageRules from "./Components/AddImage/ImageRules/ImageRules";
import ImageUpload from "./Components/AddImage/ImageUpload/ImageUpload";
import AddClipart from "./Components/AddClipart/AddClipart";

export default function DesignRose() {
  const {
    setDesignPanelMenu,
    designPanelMenu,
    setActiveTab,
    activeTab,
    handleCropComplete,
    activeSection,
  } = useContext(HomeContext);

  useEffect(() => {
    if (activeTab === null && activeSection === 2) {
      setActiveTab("AddText");
    }
  }, [activeSection]);

  return (
    <>
      <div className="DesignPanel-tools-designRose">
        <div className="DesignPanel-tools-designRose-container">
          <section className="Add">
            <div
              className="Add-item"
              style={{
                border: `1px solid ${
                  activeTab === "AddText" ? "#5C5E62" : "#9C9C9C"
                }`,
                backgroundColor:
                  activeTab === "AddText" ? "#EFBDC2" : "transparent",
              }}
              onClick={() => {
                setDesignPanelMenu(1);
                setActiveTab("AddText");
              }}
            >
              <div className="Add-item-icon">
                <img src={textAdd} alt="textAdd" />
              </div>
              <div className="Add-item-text">Add Text</div>
            </div>
            <div
              className="Add-item mx-2"
              style={{
                border: `1px solid ${
                  activeTab === "AddClipart" ? "#5C5E62" : "#9C9C9C"
                }`,
                backgroundColor:
                  activeTab === "AddClipart" ? "#EFBDC2" : "transparent",
              }}
              onClick={() => {
                setDesignPanelMenu(3);
                setActiveTab("AddClipart");
              }}
            >
              <div className="Add-item-icon">
                <img src={clipartAdd} alt="clipartAdd" />
              </div>
              <div className="Add-item-text">Add Clipart</div>
            </div>
            <div
              className="Add-item"
              style={{
                border: `1px solid ${
                  activeTab === "AddImage" ? "#5C5E62" : "#9C9C9C"
                }`,
                backgroundColor:
                  activeTab === "AddImage" ? "#EFBDC2" : "transparent",
              }}
              onClick={() => {
                setDesignPanelMenu(2);
                setActiveTab("AddImage");
              }}
            >
              <div className="Add-item-icon">
                <img src={imageAdd} alt="imageAdd" />
              </div>
              <div className="Add-item-text">Add Image</div>
            </div>
          </section>

          {designPanelMenu === 1 && <AddText />}

          {designPanelMenu === 2 && <AddImage />}

          {designPanelMenu === 21 && <ImageRules />}

          {designPanelMenu === 22 && (
            <ImageUpload onCropComplete={handleCropComplete} />
          )}

          {designPanelMenu === 3 && <AddClipart />}
        </div>
      </div>
    </>
  );
}
