import React, { useState, useCallback, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
// (این تابع کمکی برای گرفتن تصویر برش خورده است، شما می‌توانید آن را از این منبع بگیرید یا خودتان پیاده کنید)
// https://codesandbox.io/s/react-easy-crop-demo-with-cropped-output-ov7b6?file=/src/cropImage.js
import getCroppedImg from "./cropImage";
import { useContext } from "react";
import { HomeContext } from "../../../../../../Context/HomeContext";

export default function ImageUpload({ onCropComplete }) {
  const {
    imageR,
    croppedImage,
    setCroppedImage,
    setDesignPanelMenu,
    flagScrollImage,
    setFlagScrollImage,
    setImageR,
    imageUploadRefMob,
  } = useContext(HomeContext);

  const imageUploadRef = useRef(null);

  useEffect(() => {
    let observer;
    if (window.innerWidth >= 993) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // اگر بخش ImageUpload وارد viewport شد
            if (entry.isIntersecting) {
              // اگر المان در دید بود
              // اسکرول به وسط صفحه
              entry.target.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          });
        },
        {
          threshold: 0.3, // زمانی که 50% از ناحیه دیده شود
        }
      );

      if (imageUploadRef.current) {
        observer.observe(imageUploadRef.current);
      }
    }
     else {
      console.log("observer-else");
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // اگر بخش ImageUpload وارد viewport شد
            if (entry.isIntersecting) {
              // اگر المان در دید بود
              console.log("yes");
              
            } else {
              // اگر المان در دید نبود
              // اسکرول به وسط صفحه
              entry.target.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          });
        },
        { root: null, threshold: 0.2 }
      );

      if (imageUploadRef.current) {
        observer.observe(imageUploadRef.current);
      }
    }

    return () => {
      if (window.innerWidth >= 993) {
        if (imageUploadRef.current) {
          observer.unobserve(imageUploadRef.current);
        }
      }
      else {
        if (imageUploadRef.current) {
          observer.unobserve(imageUploadRef.current);
        }
      }
    };
  }, [imageUploadRef]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const inputRef = useRef(null);
  const scaleStep = 0.05;
  const maxScale = 2;
  const minScale = 0.5;
  const progressPercentage = ((zoom - minScale) / (maxScale - minScale)) * 100;

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageR(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  // این تابع زمانی فراخوانی می‌شود که کراپ به پایان رسید
  const onCropCompleteHandler = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  // زمانی که کاربر تایید می‌کند، تصویر برش‌خورده استخراج می‌شود
  const handleCropConfirm = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageR, croppedAreaPixels);
      // croppedImage یک data URL (Base64) است که می‌توانید به عنوان نتیجه کراپ استفاده کنید
      onCropComplete(croppedImage);
      setDesignPanelMenu(2);
      setFlagScrollImage(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageR, croppedAreaPixels, onCropComplete]);

  const handleZoomIn = () => {
    if (zoom < maxScale) {
      const newZoom = Math.min(
        parseFloat((zoom + scaleStep).toFixed(2)),
        maxScale
      );
      setZoom(newZoom);
    }
  };

  const handleZoomOut = () => {
    if (zoom > minScale) {
      const newZoom = Math.max(
        parseFloat((zoom - scaleStep).toFixed(2)),
        minScale
      );
      setZoom(newZoom);
    }
  };

  return (
    <div className="ImageUpload" ref={imageUploadRef}>
      <div className="ImageUpload-title">
        <span>Upload Image</span>
      </div>

      <div className="ImageUpload-container">
        <div>
          <div className="ImageUpload-view">
            <div className="ImageUpload-view-box">
              <Cropper
                image={imageR}
                crop={crop}
                zoom={zoom}
                cropShape="round"
                showGrid={false}
                aspect={1} // برای شکل دایره‌ای یا مربع، می‌توانید نسبت مورد نظر را تنظیم کنید (مثلاً 1 برای مربع)
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropCompleteHandler}
              />
            </div>

            <div className="ImageUpload-view-editHandler">
              <button onClick={handleZoomOut} disabled={zoom <= minScale}>
                <i className="bi bi-dash-lg"></i>
              </button>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              <button onClick={handleZoomIn} disabled={zoom >= maxScale}>
                <i className="bi bi-plus-lg"></i>
              </button>
            </div>
          </div>

          <div className="ImageUpload-edit">
            <div className="ImageUpload-edit-tryAgain" onClick={handleClick}>
              <p>Try another Picture</p>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleImageChange}
              />
            </div>
          </div>

          <button
            className="ImageUpload-confirmBtn"
            onClick={handleCropConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

// export default function ImageUpload() {
//   const {
//     setDesignPanelMenu,
//     image,
//     setImage,
//     setActiveSection,
//     handleScrollTo,
//     flagScrollImage,
//     setFlagScrollImage,
//   } = useContext(HomeContext);

//   const [scale, setScale] = useState(1);
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const [dragging, setDragging] = useState(false);
//   const [startPos, setStartPos] = useState({ x: 0, y: 0 });
//   const containerRef = useRef(null);

//   const scaleStep = 0.05;
//   const maxScale = 2;
//   const minScale = 0.5;

//   useEffect(() => {
//     setActiveSection(2);
//     handleScrollTo(1);
//   }, []);

//   useEffect(() => {
//     console.log("image3");
//   }, [image]);

//   const handleZoom = (newScale) => {
//     const container = containerRef.current;
//     if (!container) return;

//     // محاسبه مرکز ظرف
//     const containerCenterX = container.clientWidth / 2;
//     const containerCenterY = container.clientHeight / 2;

//     // محاسبه موقعیت جدید تصویر به طوری که نقطه‌ای که در حال حاضر در transform قرار دارد نسبت به مرکز، حفظ شود
//     // const newPosX =
//     //   containerCenterX - ((containerCenterX - position.x) * newScale) / scale;
//     // const newPosY =
//     //   containerCenterY - ((containerCenterY - position.y) * newScale) / scale;

//     const newPosX = parseFloat(
//       (
//         containerCenterX -
//         ((containerCenterX - position.x) * newScale) / scale
//       ).toFixed(2)
//     );
//     const newPosY = parseFloat(
//       (
//         containerCenterY -
//         ((containerCenterY - position.y) * newScale) / scale
//       ).toFixed(2)
//     );
//     setPosition({ x: newPosX, y: newPosY });
//     setScale(newScale);
//   };

//   // دکمه زوم این (افزایش مقیاس)
//   const handleZoomIn = () => {
//     if (scale < maxScale) {
//       // const newScale = Math.min(scale + scaleStep, maxScale);
//       const newScale = Math.min(
//         parseFloat((scale + scaleStep).toFixed(2)),
//         maxScale
//       );
//       handleZoom(newScale);
//     }
//   };

//   // دکمه زوم اوت (کاهش مقیاس)
//   const handleZoomOut = () => {
//     if (scale > minScale) {
//       // const newScale = Math.max(scale - scaleStep, minScale);
//       const newScale = Math.max(
//         parseFloat((scale - scaleStep).toFixed(2)),
//         minScale
//       );

//       handleZoom(newScale);
//     }
//   };

//   const progressPercentage = ((scale - minScale) / (maxScale - minScale)) * 100;

//   // Start drag
//   const handleDragStart = (e) => {
//     setDragging(true);
//     setStartPos({
//       x: e.clientX - position.x,
//       y: e.clientY - position.y,
//     });
//   };

//   // Dragging
//   const handleDragging = (e) => {
//     if (!dragging) return;
//     setPosition({
//       x: e.clientX - startPos.x,
//       y: e.clientY - startPos.y,
//     });
//   };

//   // End drag
//   const handleDragEnd = () => {
//     setDragging(false);
//   };

//   const inputRef = useRef(null);

//   const handleImageChange = (e) => {
//     let file = e.target.files[0];

//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleClick = () => {
//     inputRef.current.click();
//   };

//   const cropImage = () => {
//     setFlagScrollImage(false);
//     const img = new Image();
//     // اطمینان از crossOrigin در صورت نیاز
//     img.crossOrigin = "anonymous";
//     img.src = image;
//     // img.src = image + "?t=" + new Date().getTime();

//     // اگر تصویر از قبل در کش است، ممکن است onload فراخوانی نشود.

//     try {
//       if (img.complete) {
//         // console.log("img.complete"  ,img.complete);

//         processCrop(img);
//       } else {
//         console.log("else");
//         img.onload = () => {
//           setTimeout(() => {
//             processCrop(img);
//           }, 100);
//         };
//         img.onerror = (err) => {
//           console.error("Error loading image in cropImage:", err);
//         };
//       }
//     } catch (error) {
//       console.log("error-loading-processCrop");
//     }
//   };

//   const processCrop = (img) => {
//     console.log("ok");

//     // دریافت ابعاد ظرف تصویر
//     const container = containerRef.current;
//     if (!container) return; // اطمینان از وجود ظرف

//     const containerWidth = container.clientWidth;
//     const containerHeight = container.clientHeight;

//     // اندازه‌ی ناحیه کراپ طبق ماسک دایره‌ای (قطر 220 پیکسل)
//     const cropSize = 170;

//     // موقعیت ناحیه کراپ در ظرف (مرکز ظرف)
//     const cropLeft = (containerWidth - cropSize) / 2;
//     const cropTop = (containerHeight - cropSize) / 2;

//     // محاسبه نسبت‌های اولیه برای object-fit: cover
//     const containerRatio = containerWidth / containerHeight;
//     const imageRatio = img.naturalWidth / img.naturalHeight;
//     let scaleFactor, baseOffsetX, baseOffsetY;

//     if (imageRatio > containerRatio) {
//       // تصویر از لحاظ عرض بزرگتر است؛ به این معنی که ارتفاع به اندازه ظرف تنظیم می‌شود
//       scaleFactor = img.naturalHeight / containerHeight;
//       const effectiveWidth = img.naturalWidth / scaleFactor; // عرض نمایش داده شده تصویر
//       baseOffsetX = (containerWidth - effectiveWidth) / 2; // افست افقی اعمال شده توسط object-fit
//       baseOffsetY = 0;
//     } else {
//       // تصویر از لحاظ ارتفاع بزرگتر است؛ عرض به اندازه ظرف تنظیم می‌شود
//       scaleFactor = img.naturalWidth / containerWidth;
//       const effectiveHeight = img.naturalHeight / scaleFactor; // ارتفاع نمایش داده شده تصویر
//       baseOffsetX = 0;
//       baseOffsetY = (containerHeight - effectiveHeight) / 2; // افست عمودی
//     }

//     // محاسبه مختصات برداشت از تصویر در ابعاد اصلی (natural coordinates)
//     const sx = ((cropLeft - (baseOffsetX + position.x)) / scale) * scaleFactor;
//     const sy = ((cropTop - (baseOffsetY + position.y)) / scale) * scaleFactor;
//     const sWidth = (cropSize / scale) * scaleFactor;
//     const sHeight = (cropSize / scale) * scaleFactor;

//     // ایجاد canvas با ابعاد ناحیه کراپ
//     const canvas = document.createElement("canvas");
//     canvas.width = cropSize;
//     canvas.height = cropSize;
//     const ctx = canvas.getContext("2d");

//     // تنظیم clipping region به صورت دایره‌ای (دایره‌ای با شعاع 110 پیکسل)
//     // استفاده از ctx.save() و ctx.restore() برای اعمال clipping به صورت دایره‌ای
//     ctx.save();
//     ctx.beginPath();
//     ctx.arc(cropSize / 2, cropSize / 2, cropSize / 2, 0, Math.PI * 2);
//     ctx.clip();

//     // کشیدن تصویر روی canvas در ناحیه دایره‌ای
//     ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, cropSize, cropSize);
//     ctx.restore();

//     // تبدیل بوم به داده Base64
//     const croppedImage = canvas.toDataURL("image/png");
//     setImage(croppedImage);
//     setDesignPanelMenu(2);
//   };

//   return (
//     <>
//       <div className="ImageUpload">
//         <div className="ImageUpload-title">
//           <span>Upload Image</span>
//         </div>

//         <div className="ImageUpload-container">
//           <div>
//             <div className="ImageUpload-view">
//               <div
//                 className="ImageUpload-view-img"
//                 ref={containerRef}
//                 onMouseDown={handleDragStart}
//                 onMouseMove={handleDragging}
//                 onMouseUp={handleDragEnd}
//                 onMouseLeave={handleDragEnd}
//                 style={{
//                   cursor: dragging ? "grabbing" : "grab",
//                 }}
//               >
//                 <img
//                   src={image}
//                   alt=""
//                   style={{
//                     transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
//                     transformOrigin: "top left",
//                   }}
//                 />
//               </div>
//               <div className="ImageUpload-view-editHandler">
//                 <button onClick={handleZoomOut} disabled={scale <= minScale}>
//                   <i className="bi bi-dash-lg"></i>
//                 </button>
//                 <div className="progress-bar-container">
//                   <div
//                     className="progress-bar"
//                     style={{ width: `${progressPercentage}%` }}
//                   ></div>
//                 </div>
//                 <button onClick={handleZoomIn} disabled={scale >= maxScale}>
//                   <i className="bi bi-plus-lg"></i>
//                 </button>
//               </div>
//             </div>
//             <div className="ImageUpload-edit">
//               <div className="ImageUpload-edit-tryAgain" onClick={handleClick}>
//                 <p>Try another Picture</p>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   ref={inputRef}
//                   onChange={handleImageChange}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>

//         <button
//           className="ImageUpload-confirmBtn"
//           onClick={() => {
//             cropImage();
//           }}
//         >
//           Confirm
//         </button>
//       </div>
//     </>
//   );
// }
