import { useContext, useEffect } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import dataClient from "../../../../../../../assets/Json/dataClient.json";
import Loading from "../../../../Loading/Loading";

export default function AddText() {
  const { textDesign, setTextDesign, loading, setLoading } =
    useContext(HomeContext);

  let colorList = dataClient?.design?.text?.textColor;
  let fontList = dataClient?.design?.text?.textFont;

  const handleTextChange = (e) => {
    if (e.target.value.length <= 14) {
      if (e.target.id === "line1") {
        setTextDesign((prevState) => ({
          ...prevState,
          text: {
            ...prevState.text,
            line1: e.target.value,
          },
        }));
      } else if (e.target.id === "line2") {
        setTextDesign((prevState) => ({
          ...prevState,
          text: {
            ...prevState.text,
            line2: e.target.value,
          },
        }));
      } else if (e.target.id === "line3") {
        setTextDesign((prevState) => ({
          ...prevState,
          text: {
            ...prevState.text,
            line3: e.target.value,
          },
        }));
      } else if (e.target.id === "line4") {
        setTextDesign((prevState) => ({
          ...prevState,
          text: {
            ...prevState.text,
            line4: e.target.value,
          },
        }));
      } else {
        console.log("Error");
      }
    }
  };

  const handleColorSelect = (color) => {
    setTextDesign((prevState) => ({
      ...prevState,
      selectedTextColor: color,
    }));
  };

  const textAreaItem = ["line1", "line2", "line3", "line4"];

  function TextArea({ line }) {
    return (
      <div className="New-text-item" key={line}>
        <div className="New-text-item-title">{line}: </div>
        <textarea
          id={line}
          className="New-text-item-textarea"
          style={{
            fontFamily: textDesign?.fontFamily,
            color: textDesign?.selectedTextColor.hex,
            backgroundColor:
              textDesign?.selectedTextColor.hex === "#FFFFFF"
                ? "#808080"
                : "#ffffff",
          }}
          placeholder="Only 15 character"
          maxLength={15}
          value={textDesign?.text[line]}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  }

  const handleKeyDown = (e) => {
    // جلوگیری از زدن Enter
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="AddText">
        <div className="AddText-container">
          <div className="New">
            <section className="New-text">
              {textAreaItem?.map((line) => {
                return TextArea({ line });
              })}
            </section>

            <section className="New-textFont">
              <label>Font Type</label>
              <select
                value={textDesign?.fontFamily}
                onChange={async (e) => {
                  const selectedFontName = e.target.value;
                  const selectedFont = fontList.find(
                    (font) => font.name === selectedFontName
                  );

                  setLoading(true);

                  setTextDesign((prevState) => ({
                    ...prevState,
                    fontFamily: selectedFontName,
                    fontFamilyPath: selectedFont?.url,
                  }));

                  try {
                    await document.fonts.load(`1em ${selectedFontName}`);
                  } catch (err) {
                    console.error("Font load error: ", err);
                  }
                  setLoading(false);
                }}
              >
                {fontList?.map((font, index) => {
                  return (
                    <option
                      key={index}
                      value={font.name}
                      style={{ fontFamily: font.name }}
                    >
                      {font.name}
                    </option>
                  );
                })}
              </select>

              {loading === true && (
                <div className="New-textFont-loading">
                  <Loading />
                </div>
              )}
            </section>

            <section className="New-textColor">
              <label>Font color</label>
              <div className="New-textColor-list">
                {colorList?.map((color, index) => (
                  <div
                    key={index}
                    className="New-textColor-list-item"
                    style={{
                      border: `${
                        textDesign?.selectedTextColor.hex === color.hex
                          ? "2px"
                          : "1px"
                      } solid ${
                        textDesign?.selectedTextColor.hex === color.hex
                          ? "#000000"
                          : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => handleColorSelect(color)}
                  >
                    <div
                      style={{
                        backgroundColor: color.hex,
                      }}
                    />
                    <i
                      className="bi bi-check-lg New-textColor-list-item-check"
                      style={{
                        color: color.check,
                        display:
                          textDesign?.selectedTextColor.hex === color.hex
                            ? "block"
                            : "none",
                      }}
                    ></i>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
